<template>
	<div class="newRetail">
		<applyFrom @close="close" v-if="applyFromshow"></applyFrom>
		<customerService @close-popup="closePopup" v-if="customerService"></customerService>
		<div class="banner">
			<div class="banner-box flex">
				<span class="banner-box-tit">力瀚新零售解决方案</span>
				<span class="banner-box-lab">助力传统零售业转型，实现消费者、线上商城、实体门店三位一体的零售生态闭环</span>
				<div class="banner-body-title-but" @click="applyFromshow = true">获取方案</div>
			</div>
			<img style="width: 100%;height: 100%;" src="../assets/img/newRetail-banner.png">
		</div>

		<div class="Challenge">
			<div class="Challenge-tit">
				<span class="Challenge-tit-t">2020年，一场疫情让传统门店面临巨大挑战</span><br />
				<span class="Challenge-tit-b">受2020年的疫情影响，消费者闭门不出，线下门店纷纷关闭，实体经济受到重大冲击</span>
			</div>
			<div class="Challenge-cell flex">
				<div class="Challenge-cell-c">
					<img style="width: 2.01rem;" src="../assets/img/newRetail-1f-img1.png">
					<div class="Challenge-cell-c-lab">
						<div class="Challenge-cell-c-lab-t">大量商家集体要求退租</div>
						<div class="Challenge-cell-c-lab-b">门店租金高昂，疫情期间商场和街道 人流量极少，实体零售形同虚设。</div>
					</div>
				</div>

				<div class="Challenge-cell-c">
					<img style="width: 2.01rem;" src="../assets/img/newRetail-1f-img2.png">
					<div class="Challenge-cell-c-lab">
						<div class="Challenge-cell-c-lab-t">巨头餐饮业面临倒闭</div>
						<div class="Challenge-cell-c-lab-b">海底捞，西贝等，面对上万人员工资 和全国门店租金，面临破产风险。</div>
					</div>
				</div>

				<div class="Challenge-cell-c">
					<img style="width: 2.01rem;" src="../assets/img/newRetail-1f-img3.png">
					<div class="Challenge-cell-c-lab">
						<div class="Challenge-cell-c-lab-t">实体零售利润大福下降</div>
						<div class="Challenge-cell-c-lab-b">还能继续经营的门店，销售利润已大 幅下降50%以上。</div>
					</div>
				</div>
			</div>

			<div class="Challenge-but" @click="applyFromshow = true">获取方案</div>
		</div>
		<div class="worry">
			<div class="worry-tit">
				<span class="worry-tit-t">除了疫情的挑战，也许你还被这些问题困扰</span><br />
				<span class="worry-tit-b">传统零售与传统电商，一直被效率、场景、管控等问题困扰</span>
			</div>
			<div class="worry-cell flex">
				<div class="worry-cell-bor flex">
					<div class="worry-cell-bor-cell flex">
						<!-- <div class="bor-cell-icon"></div> -->
						<div class="worry-cell-bor-cell-span">
							<span class="bor-cell-tit">无法精细化运营</span><br />
							<span class="bor-cell-lab">没有打通交易敬据，运营管理不统一</span>
						</div>
					</div>
					<div class="worry-cell-bor-cell flex">
						<!-- <div class="bor-cell-icon"></div> -->
						<div class="worry-cell-bor-cell-span">
							<span class="bor-cell-tit">多店多仓管理困难</span><br />
							<span class="bor-cell-lab">线下仓库较多，缺乏多城市多仓库商城系统</span>
						</div>
					</div>
				</div>
				<div class="worry-cell-cent flex">
					<div class="worry-cell-cent-c flex">
						<div class="worry-cell-cent-c-c"><img style="width: 100%;"
								src="../assets/img/newRetail-worry-cent.png"></div>
					</div>
				</div>
				<div class="worry-cell-bor flex">
					<div class="worry-cell-bor-cell flex">
						<!-- <div class="bor-cell-icon"></div> -->
						<div class="worry-cell-bor-cell-span">
							<span class="bor-cell-tit">二次复购率低</span><br />
							<span class="bor-cell-lab">客户购买道单一，无法随时满足购物需求</span>
						</div>
					</div>
					<div class="worry-cell-bor-cell flex">
						<!-- <div class="bor-cell-icon"></div> -->
						<div class="worry-cell-bor-cell-span">
							<span class="bor-cell-tit">订单处理速度慢</span><br />
							<span class="bor-cell-lab">仓储规划不合理，订单数据不同步</span>
						</div>
					</div>
				</div>
			</div>

			<div class="Challenge-but" @click="applyFromshow = true">获取解决方案</div>
		</div>

		<div class="upgrade">
			<div class="worry-tit">
				<!--  -->
				<span class="worry-tit-t">新零售，赋能传统零售转型升级</span><br />
				<div class="worry-tit-cell flex">
					<div class="worry-tit-cell-c" :class="upgradeIndex == index ? 'befworry-tit-cell-c' : ''"
						v-for="(item,index) in upgradeList" :key="index" @click="upgradeIndex = index">{{item.title}}
					</div>
				</div>
			</div>

			<div class="upgrade-imgbox flex">
				<img :src="require('../assets/img/newRetail-upgrade'+ (upgradeIndex + 1) +'.png')">
			</div>
		
			<div class=" Challenge-but" @click="applyFromshow = true">获取新零售解决方案
			</div>
		</div>

		<div class="logistics">
			<div class="logistics-box">
				<div class="worry-tit">
					<span class="worry-tit-t">力瀚-为你轻松连接人、货、场</span><br />
					<span class="worry-tit-b">线上商城与线下门店的交易无缝融合，通过技术改变消费者的购物习惯，结合高效的物流配送，极大提升消费者的购物体验</span>
				</div>
				<div class="logistics-box-img">
					<img src="../assets/img/newRetail-logistics-img.png">
				</div>
				<div class="Challenge-but" @click="applyFromshow = true">获取新零售解决方案</div>
			</div>

			<!-- <img style="width: 100%;" src="../assets/img/newRetail-logistics-bj.png"> -->
		</div>

		<div class="transformation">
			<div class="worry-tit">
				<span class="worry-tit-t">三“卫”一体，力瀚助力新零售转型升级</span><br />
			</div>
			<div class="transformation-box">
				<img src="../assets/img/newRetail-logistics-img1.png">
			</div>
		</div>

		<div class="Administration">
			<div class="worry-tit">
				<span class="worry-tit-t">全渠道多终端覆盖，数据互通，统一后台管理</span><br />
				<span class="worry-tit-b">力瀚覆盖了多个消费终端，实现全渠道获客，让消费者在丰富的场景进入平台购物</span>
			</div>

			<div class="Administration-imgbox">
				<img src="../assets/img/newRetail-Administration.png">
				<div class="Administration-imgbox-text flex">
					<div style="width: 10%;">小程序</div>
					<div style="width: 11%;">微信端</div>
					<div style="width: 15%;">IPAD</div>
					<div style="width: 15%;">PC端</div>
					<div style="width: 15%;">后台</div>
					<div style="width: 15%;">收银系统</div>
					<div style="width: 10%;">APP</div>
					<div style="width: 9%;margin-left: 10px;">消费APP</div>
				</div>
			</div>

			<div class="Administration-butbox flex">
				<div class="Challenge-but" @click="applyFromshow = true">获取解决方案</div>
				<div class="Challenge-but2" @click="applyFromshow = true">获取系统体验</div>
			</div>
		</div>

		<div class="Marketing">
			<div class="worry-tit">
				<span class="worry-tit-t">多元化促销功能，更好做营销</span><br />
				<span class="worry-tit-b">力瀚的海量促销功能，让消费者在购物时享受更多乐趣，同样让平台拥有更多营销利器</span>
			</div>

			<div class="Administration-imgbox flex">
				<div class="Administration-imgbox-cell flex" v-for="(item,index) in MarketingList" :key="index">
					<img style="width: 0.20rem;" :src="item.img">
					<div class="imgbox-cell-tit">{{item.title}}</div>
					<div class="imgbox-cell-lab">{{item.lable}}</div>
				</div>
			</div>

			<div class="Administration-butbox flex">
				<div class="Challenge-but">更多特色功能</div>
				<div class="Challenge-but2" @click="applyFromshow = true">获取系统体验</div>
			</div>
		</div>

		<div class="function">
			<div class="function-tit">
				<span class="function-tit-tit">会员制电商分销裂变功能</span><br />
				<span class="function-tit-lab">基于微信的生态圈用户流量，让平台更快的传播获客，吸引海量用户资源</span>
			</div>

			<div class="function-Cell flex">
				<div class="Cell-bor flex">
					<div class="Cell-bor-cell">
						<img style="width: 0.48rem;padding: 0.10rem 0;" src="../assets/img/onlineRetailers-1icon.png">
						<div class="Cell-bor-cell-tit">多人拼团</div>
						<div class="Cell-bor-cell-lab">基于微信的拼团，开 团后邀请好友成团</div>
					</div>
					<div class="Cell-bor-cell">
						<img style="width: 0.42rem;padding: 0.06rem 0;" src="../assets/img/onlineRetailers-2icon.png">
						<div class="Cell-bor-cell-tit">砍价活动</div>
						<div class="Cell-bor-cell-lab">分享“帮忙砍一刀”微信内 互动交流砍价</div>
					</div>
					<div class="Cell-bor-cell">
						<img style="width: 0.51rem;padding: 0.06rem 0;" src="../assets/img/onlineRetailers-5icon.png">
						<div class="Cell-bor-cell-tit">专属推广海报</div>
						<div class="Cell-bor-cell-lab">会员邀请好友时自动 生成精美推广海报</div>
					</div>
					<div class="Cell-bor-cell">
						<img style="width: 0.37rem;padding: 0.06rem 0;" src="../assets/img/onlineRetailers-6icon.png">
						<div class="Cell-bor-cell-tit">平台财富排行榜</div>
						<div class="Cell-bor-cell-lab">分享“帮忙砍一刀”微信内 互动交流砍价</div>
					</div>
					<div class="Cell-bor-cell">
						<img style="width: 0.50rem;padding: 0.06rem 0;" src="../assets/img/onlineRetailers-9icon.png">
						<div class="Cell-bor-cell-tit">公众号营销</div>
						<div class="Cell-bor-cell-lab">吸引用户关注，定期推送 平台活动消息</div>
					</div>
					<div class="Cell-bor-cell">
						<img style="width: 0.46rem;padding: 0.06rem 0;" src="../assets/img/onlineRetailers-10icon.png">
						<div class="Cell-bor-cell-tit">个性会员主页</div>
						<div class="Cell-bor-cell-lab">成为会员后的个性化会员 中心，彰显身份</div>
					</div>
				</div>
				<div>
					<img style="width: 3.39rem;" src="../assets/img/onlineRetailers-Applet.png">
				</div>
				<div class="Cell-bor flex">
					<div class="Cell-bor-cell">
						<img style="width: 0.46rem;padding: 0.06rem 0;" src="../assets/img/onlineRetailers-3icon.png">
						<div class="Cell-bor-cell-tit">高级VIP中心</div>
						<div class="Cell-bor-cell-lab">展示高级会员收益明细及 下级会员概况</div>
					</div>
					<div class="Cell-bor-cell">
						<img style="width: 0.37rem;padding: 0.06rem 0;" src="../assets/img/onlineRetailers-4icon.png">
						<div class="Cell-bor-cell-tit">会员专属微店</div>
						<div class="Cell-bor-cell-lab">每个会员都有自己的 专属店铺分享商品</div>
					</div>
					<div class="Cell-bor-cell">
						<img style="width: 0.47rem;padding: 0.06rem 0;" src="../assets/img/onlineRetailers-7icon.png">
						<div class="Cell-bor-cell-tit">会员粉丝管理</div>
						<div class="Cell-bor-cell-lab">会员可以随时查看下级会员 列表及贡献详情</div>
					</div>
					<div class="Cell-bor-cell">
						<img style="width: 0.41rem;padding: 0.06rem 0;" src="../assets/img/onlineRetailers-8icon.png">
						<div class="Cell-bor-cell-tit">赚取佣金明细</div>
						<div class="Cell-bor-cell-lab">查看下级会员每笔订单 是否分成的佣金明细</div>
					</div>
					<div class="Cell-bor-cell">
						<img style="width: 0.36rem;padding: 0.06rem 0;" src="../assets/img/onlineRetailers-11icon.png">
						<div class="Cell-bor-cell-tit">商品分享传播</div>
						<div class="Cell-bor-cell-lab">通过社会化分享将商品直 接对外传播</div>
					</div>
					<!-- <div class="Cell-bor-cell">
						<img style="width: 0.42rem;padding: 0.06rem 0;" src="../assets/img/onlineRetailers-12icon.png">
						<div class="Cell-bor-cell-tit">社区圈子互动</div>
						<div class="Cell-bor-cell-lab">发布帖子关联商品，打造 内容电商模式</div>
					</div> -->
				</div>
			</div>
		</div>

		<!-- <div class="broadcast">
			<div class="worry-tit">
				<span class="worry-tit-t">小程序直播，热门的直播带货新玩法</span><br />
				<span class="worry-tit-b">基于微信直播小程序研发，应对大流量系统更加稳定，播放流畅自如，带来优质的直播购物体验</span>
			</div>

			<div class="Administration-imgbox">
				<img src="../assets/img/newRetail-broadcast-img.png">
			</div>

			<div class="Administration-butbox flex">
				<div class="Challenge-but" @click="customerService = true">了解小程序直播</div>
				<div class="Challenge-but2" @click="applyFromshow = true">获取系统体验</div>
			</div>
		</div> -->

		<div class="newRetailBot" style="background-color: #fff;">
			<div class="worry-tit">
				<span class="worry-tit-t">转型新零售，就选力瀚</span><br />
				<span class="worry-tit-b">新零售不止是一个概念，而是一个全新的消费体验升级，而力瀚就是顺应时代而生，一款专为新零售而生的电商系统 </span>
			</div>

			<div class="newRetailBot-box flex">
				<div class="newRetailBot-box-cell">
					<img style="width: 0.63rem;" src="../assets/img/newRetail-Bot-icon1.png">
					<div class="newRetailBot-box-cell-t">消费升级</div>
					<div class="newRetailBot-box-cell-b">平台、技术、服务的全面升 级，重新定义零售模式</div>
				</div>
				<div class="newRetailBot-box-cell">
					<img style="width: 0.63rem;" src="../assets/img/newRetail-Bot-icon2.png">
					<div class="newRetailBot-box-cell-t">新技术</div>
					<div class="newRetailBot-box-cell-b">前沿的电商系统，移动支付更加 便捷化，技术驱动商业未来</div>
				</div>
				<div class="newRetailBot-box-cell">
					<img style="width: 0.63rem;" src="../assets/img/newRetail-Bot-icon3.png">
					<div class="newRetailBot-box-cell-t">线上线下融合</div>
					<div class="newRetailBot-box-cell-b">用产品和技术实现线上线下的 业务结合，提升购物体验</div>
				</div>
				<div class="newRetailBot-box-cell">
					<img style="width: 0.63rem;" src="../assets/img/newRetail-Bot-icon4.png">
					<div class="newRetailBot-box-cell-t">大数据</div>
					<div class="newRetailBot-box-cell-b">将会员每次消费在后台进行 统计并生成智能报表</div>
				</div>
				<div class="newRetailBot-box-cell">
					<img style="width: 0.63rem;" src="../assets/img/newRetail-Bot-icon5.png">
					<div class="newRetailBot-box-cell-t">高效物流</div>
					<div class="newRetailBot-box-cell-b">全渠道订单统一后台管理平台 极速就近配送</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import applyFrom from '@/components/applyFrom.vue';
	import customerService from '@/components/customerService.vue';
	export default {
		data() {
			return {
				applyFromshow: false,
				customerService: false,
				upgradeIndex: 0,
				upgradeList: [{
						title: '全渠道多终端覆盖',
						img: ''
					},
					{
						title: '线上+线下无缝融合',
						img: ''
					},
					{
						title: '打破消费场景限制',
						img: ''
					},
					{
						title: '精准用户数据营销',
						img: ''
					}
				],
				MarketingList: [{
						img: require('../assets/img/newRetail-Marketing-1icon.png'),
						title: '满减满赠',
						lable: '满额即优惠，促进消费'
					},
					{
						img: require('../assets/img/newRetail-Marketing-2icon.png'),
						title: '限时抢购',
						lable: '显示促销折扣商品'
					},
					{
						img: require('../assets/img/newRetail-Marketing-3icon.png'),
						title: '推荐有礼',
						lable: '推荐引流享分成'
					},
					{
						img: require('../assets/img/newRetail-Marketing-5icon.png'),
						title: '优惠券',
						lable: '营销必备优惠利器'
					},
					{
						img: require('../assets/img/newRetail-Marketing-4icon.png'),
						title: '超值礼包',
						lable: '超值礼包组合推荐'
					},
					{
						img: require('../assets/img/newRetail-Marketing-6icon.png'),
						title: '推荐组合',
						lable: '优惠搭配增进消费'
					},
					{
						img: require('../assets/img/newRetail-Marketing-7icon.png'),
						title: '分期购',
						lable: '优惠搭配增进消费'
					},
					{
						img: require('../assets/img/newRetail-Marketing-8icon.png'),
						title: '红包',
						lable: '贴心红包发放'
					},
					// {
					// 	img: require('../assets/img/newRetail-Marketing-9icon.png'),
					// 	title: '储值卡',
					// 	lable: '固定额度储值卡'
					// },
					{
						img: require('../assets/img/newRetail-Marketing-10icon.png'),
						title: '礼品卡',
						lable: '用于提取指定商品'
					},

				]
			}
		},
		components: {
			// Canvas，
			applyFrom,
			customerService
		},

		methods: {
			close() {
				this.applyFromshow = false;
			},
			closePopup() {
				this.customerService = false
			},
		}
	}
</script>

<style lang="scss" scoped>
	@media screen and (max-width:750px) {
		.banner-body-title-but {
			margin-top: 0.2rem !important;
		}

		.banner-box-tit {
			margin-bottom: 0.2rem !important;
		}

		.banner-box-lab {
			margin-bottom: 0.05rem !important;
		}

		.Administration-imgbox {
			width: 90% !important;
		}

		.upgrade-imgbox {
			width: 90% !important;
		}

		.worry-tit-cell {
			width: 90% !important;
		}

		.newRetailBot-box {
			width: 90% !important;
		}

		.banner {
			height: 3rem;

			.banner-box {
				width: 90% !important;

				// right: 0;
			}
		}

		.Challenge-cell {
			width: 90% !important;
		}

		.function-Cell {
			width: 100%;

			.Cell-bor-cell {
				width: 1.6rem !important;
			}
		}

		.worry-cell {
			width: 95% !important;

			.worry-cell-bor {
				width: auto !important;
				height: 3rem !important;

				.worry-cell-bor-cell {
					width: 1.8rem !important;

					.worry-cell-bor-cell-span {
						line-height: 0.12rem !important;
					}
				}
			}
		}
	}

	.befworry-tit-cell-c {
		position: relative;
		font-size: .17rem !important;
		font-weight: 700 !important;
		color: #333 !important;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			width: 100%;
			left: 0;
			right: 0;
			opacity: 1;
			height: 100%;
			border-bottom: 2px solid #239ef7;
			transition: all .2s linear;
		}
	}

	// img{
	// 	width: 100%;
	// }
	.banner {
		width: 100%;
		// height: 3.00rem;
		// padding-top: 30px;
		position: relative;
		z-index: 101;

		.banner-box {
			position: absolute;
			width: 70%;
			height: 100%;
			right: 0;
			flex-direction: column;
			align-items: flex-start;
			text-align: left;

			.banner-box-tit {
				font-size: 0.36rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				margin-bottom: 0.30rem;
			}

			.banner-box-lab {
				font-size: 0.17rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				margin-bottom: 0.20rem;
			}

			.banner-body-title-but {
				width: 2.00rem;
				height: 0.5rem;
				background-image: url(../assets/img/onlineRetailerstop-but.png);
				background-repeat: no-repeat;
				background-size: 100%;
				font-size: 0.24rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				text-align: center;
				line-height: 0.50rem;
				margin-top: 0.40rem;
			}
		}

		// background-image: url(../assets/img/Journalismbanner.png);
	}

	.Challenge {
		width: 100%;
		padding: 0.60rem 0;

		.Challenge-tit {
			width: 100%;
			text-align: center;
			line-height: 0.30rem;

			.Challenge-tit-t {
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333333;
			}

			.Challenge-tit-b {
				font-size: 0.12rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #666666;
			}
		}

		.Challenge-but {
			width: 2.00rem;
			height: 0.50rem;
			background-image: url(../assets/img/onlineRetailerstop-but.png);
			background-repeat: no-repeat;
			background-size: 100%;
			margin: 0 auto;
			font-size: 0.20rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #FFFFFF;
			text-align: center;
			line-height: 0.50rem;
			margin-top: 0.60rem;
		}

		.Challenge-cell {
			width: 50%;
			margin: 0 auto;
			justify-content: space-between;
			margin-top: 0.60rem;

			.Challenge-cell-c {
				width: 2.00rem;
				// height: 250px;
				box-shadow: 0px 1px 12px 0px rgba(174, 174, 174, 0.2);

				.Challenge-cell-c-lab {
					width: 90%;
					margin: 0 auto;
					padding: 0.10rem 0;

					.Challenge-cell-c-lab-t {
						font-size: 0.14rem;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #333333;
					}

					.Challenge-cell-c-lab-b {
						font-size: 0.10rem;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #666666;
						margin-top: 0.10rem;
					}
				}
			}
		}
	}

	.worry {
		width: 100%;
		background-color: #f7f7f7;
		padding: 0.60rem 0;

		.worry-tit {
			width: 100%;
			text-align: center;
			line-height: 0.30rem;

			.worry-tit-t {
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333333;
			}

			.worry-tit-b {
				font-size: 0.12rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #666666;
			}
		}

		.Challenge-but {
			width: 2.00rem;
			height: 0.50rem;
			background-image: url(../assets/img/onlineRetailerstop-but.png);
			background-repeat: no-repeat;
			background-size: 100%;
			margin: 0 auto;
			font-size: 0.20rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #FFFFFF;
			text-align: center;
			line-height: 0.50rem;
			margin-top: 0.60rem;
		}

		.worry-cell {
			// width: 50%;
			margin: 0 auto;
			margin-top: 0.60rem;

			.worry-cell-bor {
				width: 2.70rem;
				height: 2.60rem;
				flex-direction: column;
				justify-content: space-around;

				.worry-cell-bor-cell {
					width: 2.25rem;
					// height: 0.75rem;
					// margin-bottom: 30px;
					padding: 0.20rem 0.10rem;
					background: #FFFFFF;
					box-shadow: 0px 1px 12px 0px rgba(174, 174, 174, 0.2);
					align-items: initial;
					justify-content: initial;

					.worry-cell-bor-cell-span {
						flex: 1;
						text-align: left;
						line-height: 0.03rem;
						padding-left: 0.20rem;
						padding-right: 0.10rem;
					}

					.bor-cell-icon {
						width: 0.6rem;
						height: 0.6rem;
						border: 1px solid #2E8AF9;
						border-radius: 100%;
					}

					.bor-cell-tit {
						font-size: 0.13rem;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #333333;
						position: relative;

						&:before {
							content: '';
							position: absolute;
							left: -0.20rem;
							top: 0.05rem;
							width: 0.06rem;
							height: 0.06rem;
							border: 1px solid #2E8AF9;
							border-radius: 100%;
						}
					}

					.bor-cell-lab {
						font-size: 0.09rem;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #999999;
					}
				}
			}

			.worry-cell-cent {
				width: 2.60rem;
				height: 2.60rem;
				background: #EDEEEF;
				border-radius: 50%;
				margin: 0 0.20rem;

				.worry-cell-cent-c {
					width: 2.25rem;
					height: 2.25rem;
					background: #F5F6F7;
					border-radius: 100%;

					.worry-cell-cent-c-c {
						width: 2.00rem;
						height: 2.00rem;
						border-radius: 100%;
					}
				}
			}
		}
	}

	.upgrade {
		width: 100%;
		padding: 0.60rem 0;

		.worry-tit {
			width: 100%;
			text-align: center;
			line-height: 0.30rem;

			.worry-tit-t {
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333333;
			}

			.worry-tit-cell {
				width: 70%;
				margin: 0 auto;
				margin-top: 0.3rem;
				border-bottom: 1px dashed #666;

				.worry-tit-cell-c {
					flex: 1;
					padding: 0.2rem;
					font-size: .15rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #666;
				}
			}
		}

		.Challenge-but {
			width: 2.00rem;
			height: 0.50rem;
			background-image: url(../assets/img/onlineRetailerstop-but.png);
			background-size: 100%;
			margin: 0 auto;
			font-size: 0.20rem;
			font-family: Source Han Sans CN;
			font-weight: 400;
			color: #FFFFFF;
			text-align: center;
			line-height: 0.50rem;
			margin-top: 0.60rem;
		}

		.upgrade-imgbox {
			width: 50%;
			margin: 0 auto;
			margin-top: 0.6rem;

			.upgrade-imgbox-text {
				flex: 1;
				text-align: left;

				.Challenge-but {
					width: 1.20rem;
					height: 0.30rem;
					background: linear-gradient(90deg, #2E89F9, #06D8EE);
					font-size: 0.15rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #FFFFFF;
					text-align: center;
					line-height: 0.30rem;
					margin: 0.20rem 0;
				}

				.upgrade-imgbox-text-t {
					width: 100%;
					font-size: 0.18rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #333333;

				}

				.upgrade-imgbox-text-b {
					width: 3.00rem;
					font-size: 0.10rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #999999;
					margin-top: 0.20rem;
				}
			}
		}
	}

	.logistics {
		width: 100%;
		position: relative;
		background-image: url(../assets/img/newRetail-logistics-bj.png);

		.logistics-box {
			width: 100%;
			height: 100%;
			// position: absolute;
			position: relative;
			z-index: 11;
			padding: 0.60rem 0;

			.worry-tit {
				width: 100%;
				text-align: center;
				line-height: 0.30rem;

				.worry-tit-t {
					font-size: 0.27rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #fff;
				}

				.worry-tit-b {
					font-size: 0.12rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #fff;
				}
			}

			.Challenge-but {
				width: 2.00rem;
				height: 0.50rem;
				background-image: url(../assets/img/onlineRetailerstop-but.png);
				background-repeat: no-repeat;
				background-size: 100%;
				margin: 0 auto;
				font-size: 0.20rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				text-align: center;
				line-height: 0.50rem;
				margin-top: 0.60rem;
			}

			.logistics-box-img {
				// width: 70%;

				width: 13.59rem;

				img {
					width: 100%;
				}

				margin: 0 auto;
				margin-top: 0.60rem;
			}
		}
	}

	.transformation {
		width: 100%;
		padding: 0.80rem 0;

		.worry-tit {
			width: 100%;
			text-align: center;
			line-height: 0.30rem;

			.worry-tit-t {
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #000;
			}
		}

		.transformation-box {
			width: 15.14rem;
			// width: 75%;

			img {
				width: 100%;
			}

			margin: 0 auto;
			margin-top: 0.80rem;
		}
	}

	.Administration {
		width: 100%;
		padding: 0.60rem 0;
		background: #060628;

		.worry-tit {
			width: 100%;
			text-align: center;
			line-height: 0.30rem;

			.worry-tit-t {
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #fff;
			}

			.worry-tit-b {
				font-size: 0.12rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #fff;
			}
		}

		.Administration-imgbox {
			width: 12.37rem;
			// width: 68%;
			margin: 0 auto;
			margin-top: 0.60rem;

			img {
				width: 100%;
			}

			.Administration-imgbox-text {
				width: 100%;
				margin-top: 0.20rem;
				font-size: 0.10rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
			}
		}

		.Administration-butbox {
			width: 100%;
			line-height: 0.40rem;
			margin: 0.20rem 0;
			margin-top: 0.60rem;

			.Challenge-but {
				width: 1.30rem;
				height: 0.40rem;
				background: linear-gradient(90deg, #2E89F9, #06D8EE);
				font-size: 0.11rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				text-align: center;
			}

			.Challenge-but2 {
				width: 1.30rem;
				height: 0.40rem;
				border: 1px dashed #ccc;
				font-size: 0.11rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				text-align: center;
				margin-left: 0.30rem;
			}
		}
	}

	.Marketing {
		width: 100%;
		padding: 0.60rem 0;

		.worry-tit {
			width: 100%;
			text-align: center;
			line-height: 0.30rem;


			.worry-tit-t {
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333;
			}

			.worry-tit-b {
				font-size: 0.12rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333;
			}
		}

		.Administration-imgbox {
			// width: 44%;
			width: 8.40rem;
			margin: 0 auto;
			margin-top: 0.60rem;
			flex-wrap: wrap;
			justify-content: space-evenly;

			.Administration-imgbox-cell {
				width: 1.20rem;
				height: 1.00rem;
				border: 1px solid #E6E7EB;
				flex-direction: column;
				padding: 0 0.10rem;
				margin-top: 0.10rem;

				.imgbox-cell-tit {
					font-size: 0.13rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #333333;
					margin-top: 0.10rem;
				}

				.imgbox-cell-lab {
					font-size: 0.09rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #999999;
					margin-top: 0.10rem;
				}
			}
		}

		.Administration-butbox {
			width: 100%;
			line-height: 0.40rem;
			margin: 0.20rem 0;
			margin-top: 0.60rem;

			.Challenge-but {
				width: 1.30rem;
				height: 0.40rem;
				background: linear-gradient(90deg, #2E89F9, #06D8EE);
				font-size: 0.11rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				text-align: center;
			}

			.Challenge-but2 {
				width: 1.30rem;
				height: 0.40rem;
				border: 1px dashed #ccc;
				font-size: 0.11rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #2896F7;
				text-align: center;
				margin-left: 0.30rem;
			}
		}
	}

	.function {
		width: 100%;
		background-color: #f7f7f7;
		padding: 0.60rem 0;

		.function-tit {
			width: 100%;
			text-align: center;
			line-height: 0.30rem;

			.function-tit-tit {
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333333;
			}

			.function-tit-lab {
				font-size: 0.12rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #666666;
			}
		}

		.function-Cell {
			// width: 50%;
			margin: 0 auto;
			margin-top: 0.40rem;

			.Cell-bor {
				width: 4.00rem;
				// height: 6.80rem;
				flex-wrap: wrap;
				// align-items: initial;
				justify-content: space-between;

				.Cell-bor-cell {
					width: 1.80rem;
					// height: 1.60rem;
					margin-right: 0.1rem;
					margin-bottom: 0.1rem;
					// padding: 10px 0;
					line-height: 0.2rem;
					background-color: #fff;

					.Cell-bor-cell-tit {
						font-size: 0.20rem;
						padding: 0.05rem;
						font-family: Source Han Sans CN;
						font-weight: 400;
						color: #333333;
						margin-bottom: 0.10rem;
						margin-top: 0.2rem;
					}

					.Cell-bor-cell-lab {
						font-size: 0.14rem;
						font-family: Source Han Sans CN;
						font-weight: 400;
						padding: 0 0.05rem;
						color: #999999;
					}
				}
			}
		}
	}

	.broadcast {
		width: 100%;
		padding: 0.60rem 0;

		.worry-tit {
			width: 100%;
			text-align: center;
			line-height: 0.30rem;

			.worry-tit-t {
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333;
			}

			.worry-tit-b {
				font-size: 0.12rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333;
			}
		}

		.Administration-imgbox {
			width: 15.17rem;
			// width: 75%;
			margin: 0 auto;
			margin-top: 0.60rem;

			img {
				width: 100%;
			}
		}

		.Administration-butbox {
			width: 100%;
			line-height: 0.40rem;
			margin: 0.20rem 0;
			margin-top: 0.60rem;

			.Challenge-but {
				width: 1.30rem;
				height: 0.40rem;
				background: linear-gradient(90deg, #2E89F9, #06D8EE);
				font-size: 0.11rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #FFFFFF;
				text-align: center;
			}

			.Challenge-but2 {
				width: 1.30rem;
				height: 0.40rem;
				border: 1px dashed #ccc;
				font-size: 0.11rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #2E89F9;
				text-align: center;
				margin-left: 0.30rem;
			}
		}
	}

	.newRetailBot {
		width: 100%;
		background-color: #f7f7f7;
		padding: 0.60rem 0;

		.worry-tit {
			width: 100%;
			text-align: center;
			line-height: 0.30rem;

			.worry-tit-t {
				font-size: 0.27rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333;
			}

			.worry-tit-b {
				font-size: 0.12rem;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #333;
			}
		}

		.newRetailBot-box {
			width: 50%;
			margin: 0 auto;
			justify-content: space-between;
			margin-top: 0.60rem;

			.newRetailBot-box-cell {
				width: 18%;

				.newRetailBot-box-cell-t {
					font-size: 0.13rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #333333;
					margin-top: 0.10rem;
				}

				.newRetailBot-box-cell-b {
					font-size: 0.09rem;
					font-family: Source Han Sans CN;
					font-weight: 400;
					color: #999999;
					margin-top: 0.10rem;
				}
			}
		}
	}
</style>
